import React, { useState, useEffect } from "react";
import "./Modal.scss";

export default function Modal() {

  const [modal, setModal] = useState(false);

  const closePopUp = () => {
    sessionStorage.setItem("seenPopUp", true);
    setModal(!modal);
  };
  
  useEffect(() => {
    let returningUser = sessionStorage.getItem("seenPopUp");
    setModal(!returningUser);
  }, []);
  
  return (
    <>
      {modal && (
        <div className="modal2">
          <div className="overlay2"></div>
          <div className="modal-content2">
            <div className="modal-text">
            <h2>Attention to our readers</h2>
            <p>
            We are informing that this web site has no any links and affiliations with rough-polished.expert, 
            as well as platinum market and Norilsk Nickel information buisness activities.
            </p>
            </div> 
            <button className="close-modal2" onClick={closePopUp}>
              X
            </button>
          </div>
        </div>
      )}
     
    </>
  );
}
