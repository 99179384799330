import React, { useEffect, useState } from 'react'

import MainContentBlock from '../../Components/MainContentBlock/MainContentBlock'
import axios from 'axios'
import Modal from '../../Components/Popup/Modal'

const Main = ({ }) => {
    // const [articles, setArticles] = useState([])




    // const data = require('../../data.json')
    //запрос
    return (
        <><Modal></Modal><div className='main-page'>
            <div className="rough-block">
                <MainContentBlock blockName={'rough'} />
            </div>
            <div className='polished-block'>
                <MainContentBlock blockName={'polished'} />
            </div>
        </div></>
    )
}

export default Main